import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ui/button';
import {
  Subtitle,
  StyledBox,
  Container,
  PacksPerWeekText,
  DailyCaloriesText,
  PriceText,
  Text,
  TextHeader,
  BoxStyledTitle,
  DeliveryFrequencyText,
  AddonItem,
} from './styles';

export const RecommendNewPlanForm = ({
  confirmChangeSubtitle,
  buttonLabel,
  buttonVariant,
  planTypeLabel,
  amountOfPack,
  price,
  recipes,
  dailyCalories,
  olliePlanTitle,
  deliveryFrequency,
  deliveryTitle,
  onButtonClick,
  isCurrentPlan = false,
  isSubmitting,
  ollieAddonsTitle,
  addons,
  showAddons,
}) => (
  <StyledBox>
    <Container>
      <BoxStyledTitle isCurrentPlan={isCurrentPlan}>
        {planTypeLabel}
      </BoxStyledTitle>
    </Container>

    <PacksPerWeekText>{amountOfPack}</PacksPerWeekText>
    <DailyCaloriesText>{dailyCalories}</DailyCaloriesText>
    <PriceText>{price}</PriceText>

    <TextHeader>{olliePlanTitle}</TextHeader>
    <Text>{recipes}</Text>

    {showAddons && (
      <>
        <TextHeader>{ollieAddonsTitle}</TextHeader>
        <Text>
          {addons.length > 0
            ? addons.map(addon => <AddonItem key={addon}>{addon}</AddonItem>)
            : 'None'}
        </Text>
      </>
    )}

    <TextHeader>{deliveryTitle}</TextHeader>
    <DeliveryFrequencyText>{deliveryFrequency}</DeliveryFrequencyText>
    <Button
      verticalPadding={3}
      disabled={isSubmitting}
      variant={buttonVariant}
      onClick={onButtonClick}
    >
      {buttonLabel}
    </Button>
    <Subtitle>{confirmChangeSubtitle}</Subtitle>
  </StyledBox>
);

RecommendNewPlanForm.displayName = 'RecommendNewPlanForm';
RecommendNewPlanForm.propTypes = {
  confirmChangeSubtitle: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonVariant: PropTypes.string.isRequired,
  planTypeLabel: PropTypes.string.isRequired,
  amountOfPack: PropTypes.string.isRequired,
  dailyCalories: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  recipes: PropTypes.string.isRequired,
  olliePlanTitle: PropTypes.string.isRequired,
  deliveryFrequency: PropTypes.string.isRequired,
  deliveryTitle: PropTypes.string.isRequired,
  isCurrentPlan: PropTypes.bool,
  onButtonClick: PropTypes.func,
  isSubmitting: PropTypes.bool.isRequired,
  showAddons: PropTypes.bool.isRequired,
  ollieAddonsTitle: PropTypes.string,
  addons: PropTypes.arrayOf(PropTypes.string),
};
