'use client';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useLocalStorage } from '../hooks';
import { usePet, usePets } from '../pets';

const LOCAL_STORAGE_PET = 'selectedPet';

export const useCurrentPet = () => {
  const { pets, isLoading: petsAreLoading } = usePets({
    hasSubscription: true,
  });
  const router = useRouter();
  const petIdFromUrl = router.query.pet_id;
  const [locallyStoredPetId, setLocallyStoredPet] =
    useLocalStorage(LOCAL_STORAGE_PET);

  const selectedPet = useMemo(() => {
    if (!petsAreLoading) {
      if (petIdFromUrl) {
        return pets.find(pet => pet.id === petIdFromUrl);
      }

      if (locallyStoredPetId) {
        return pets.find(pet => pet.id === locallyStoredPetId);
      }

      if (pets?.[0]) {
        return pets[0];
      }
    }

    return null;
  }, [petsAreLoading, locallyStoredPetId, pets, petIdFromUrl]);

  const usePetApi = usePet(selectedPet?.id);

  useEffect(() => {
    if (!petsAreLoading && selectedPet && selectedPet.id !== petIdFromUrl) {
      let hash = new URL(window.location.href).hash;
      router.replace({
        pathname: router.pathname,
        query: { ...router.query, pet_id: selectedPet.id },
        hash,
      });
    } else if (!petsAreLoading && selectedPet) {
      setLocallyStoredPet(selectedPet.id);
    } else if (!petsAreLoading && pets.length > 0) {
      router.replace({
        pathname: router.pathname,
        query: { ...router.query, pet_id: pets[0].id },
      });
    }
  }, [
    petIdFromUrl,
    pets,
    petsAreLoading,
    router,
    selectedPet,
    setLocallyStoredPet,
  ]);

  return {
    ...usePetApi,
    isLoading: petsAreLoading || usePetApi.isLoading || !selectedPet,
  };
};
