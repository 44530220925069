import * as yup from 'yup';

export const schema = yup
  .object({
    planTypeLabel: yup.string().required(),
    confirmChangeSubtitle: yup.string().required(),
    buttonLabel: yup.string().required(),
    buttonVariant: yup.string().required(),
    deliveryTitle: yup.string().required(),
    ollieAddonsTitle: yup.string().required(),
    isCurrentPlan: yup.bool(),
    olliePlanTitle: yup.string().required(),
    amountOfPack: yup.string().required(),
    price: yup.string().required(),
    deliveryFrequency: yup.string().required(),
  })
  .transform(value => ({
    planTypeLabel: value.plan_type_label,
    confirmChangeSubtitle: value.confirm_change_subtitle,
    buttonLabel: value.button_label,
    buttonVariant: value.button_variant,
    deliveryTitle: value.delivery_title,
    ollieAddonsTitle: value.addons_title,
    isCurrentPlan: value.is_current_plan,
    olliePlanTitle: value.plan_title,
    amountOfPack: value.amount_of_packs_text,
    price: value.price_text,
    deliveryFrequency: value.delivery_frequency_text,
  }));
