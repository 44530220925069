import * as yup from 'yup';
import { planSelectionSchemaAccount as plan_selection_edit_flow } from './plan';
import { schema as recommend_new_plan_form } from './recommend_new_plan_form';
import { schema as recommend_new_plan_container } from './recommend_new_plan_container';
import { addonSchema as extras } from './addons';
import { schema as pup_success_info } from './pup_success_info';
import { schema as plan_success_page } from './plan_success_page';

const redirect_route = yup.object({});

const plan_selection_reactivation_flow = plan_selection_edit_flow;

export {
  redirect_route,
  plan_selection_edit_flow,
  plan_selection_reactivation_flow,
  extras,
  recommend_new_plan_form,
  recommend_new_plan_container,
  pup_success_info,
  plan_success_page,
};
