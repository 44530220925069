import * as yup from 'yup';
import { schema as headerSchema } from '../../cms/builders/header';
import { schema as footerSchema } from '../../cms/builders/footer';
import { schema as pupSuccessInfoSchema } from './pup_success_info';

export const schema = yup
  .object({
    header: headerSchema,
    footer: footerSchema,
    pupSuccessInfo: pupSuccessInfoSchema,
  })
  .transform(value => ({
    header: value.header,
    footer: value.footer,
    pupSuccessInfo: value.pup_success_info,
  }));
