import * as yup from 'yup';
import { schema as recommendNewPlanFormSchema } from './recommend_new_plan_form';

export const schema = yup
  .object({
    planSelectionInfo: yup.object({
      headingText: yup.string().required(),
      subtitle: yup.string(),
      plans: yup.array(recommendNewPlanFormSchema).required(),
    }),
  })
  .transform(value => ({
    planSelectionInfo: {
      headingText: value.headline,
      subtitle: value.subtitle,
      plans: value.plans,
    },
  }));
