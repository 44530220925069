import * as yup from 'yup';
import { requiredImageFromCMSSchema, richTextFromCMSSchema } from '../../cms';
import { full_ingredients_modal } from '../../cms/builders';

const itemSchema = yup.object({
  title: yup.string().required(),
  label: yup.string().required(),
  benefit: yup.string().required(),
  callout: yup.string().optional(),
  value: yup.string().required(),
  description: richTextFromCMSSchema,
  img: requiredImageFromCMSSchema,
  unavailableText: yup.string(),
  unavailableLabel: yup.string(),
  priceSymbol: yup.string().optional(),
  pricingInfo: yup.object({
    price: yup.string(),
    discount: yup.string(),
  }),
  showMoreDetails: yup.bool().required(),
  level: yup.string(),
});

export const planSelectionSchemaAccount = yup
  .object({
    planSelectionInfo: yup.object({
      headline: yup.string().required(),
      subheadline: yup.string().required(),
      nextStepText: yup.string(),
      saveText: yup.string(),
      items: yup
        .array(
          itemSchema
            .from('display_name', 'title')
            .from('slug', 'value')
            .from('image', 'img')
            .from('unavailable_text', 'unavailableText')
            .from('unavailable_label', 'unavailableLabel')
            .from('show_more_details', 'showMoreDetails')
            .from('price_symbol', 'priceSymbol'),
        )
        .required(),
      collapsedItems: yup
        .array(
          itemSchema
            .from('display_name', 'title')
            .from('slug', 'value')
            .from('image', 'img')
            .from('unavailable_text', 'unavailableText')
            .from('unavailable_label', 'unavailableLabel')
            .from('show_more_details', 'showMoreDetails')
            .from('price_symbol', 'priceSymbol'),
        )
        .required(),
      modals: yup.array(full_ingredients_modal.schema).required(),
    }),
  })
  .transform(({ body, ...value }) => ({
    planSelectionInfo: {
      ...value,
      nextStepText: value.next_step_text,
      saveText: value.save_text,
      items: value.recipe_selector
        .filter(item => !item.hidden)
        .map(recipe => ({
          ...recipe,
          pricingInfo: {
            price: recipe.price,
            discount: recipe.discount,
          },
        })),
      collapsedItems: value.recipe_selector
        .filter(item => item.hidden)
        .map(recipe => ({
          ...recipe,
          pricingInfo: {
            price: recipe.price,
            discount: recipe.discount,
          },
        })),
    },
  }));
