import * as yup from 'yup';
import { addonSectionSchema } from '../../cms/builders/addon_sections';
import { addonModalSchema } from '../../cms/builders/addon_modal';

export const addonSchema = yup
  .object({
    addonSelectionInfo: yup.object({
      headline: yup.string(),
      subheadline: yup.string(),
      addonSections: yup.array(addonSectionSchema),
      mobileCtaText: yup.string(),
      desktop_cta_text: yup.string(),
      body: yup.array(yup.mixed()),
      modals: yup.array(addonModalSchema),
    }),
  })
  .transform(({ body, ...value }) => ({
    addonSelectionInfo: {
      headline: value.headline,
      subheadline: value.subheadline,
      addonSections: value.addon_sections,
      mobileCtaText: value.mobile_cta_text,
      desktopCtaText: value.desktop_cta_text,
      body,
      modals: value.modals,
    },
  }));
