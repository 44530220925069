import * as builders from './builders';

export const translateContentToProps = content => {
  const cmsComponent = content?.component;

  if (!cmsComponent) return {};
  // eslint-disable-next-line import/namespace
  const builder = builders[cmsComponent];
  return builder.validateSync(content);
};
