'use client';
import styled from 'styled-components';

export const StyledBox = styled.div`
  background-color: ${({ theme }) => theme.colors.background.lightest};
  border-radius: 32px;
  padding: ${({ theme }) => theme.spacing(4, 6)};
  display: flex;
  flex-direction: column;
  margin: 0;
`;

export const AddonItem = styled.p``;

export const Subtitle = styled.p`
  ${props => props.theme.typography.subheading2}
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-top: ${props => props.theme.spacing(6)};
  text-align: center;
`;

export const Container = styled.div`
  text-align: left;
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const PacksPerWeekText = styled.span`
  ${({ theme }) => theme.typography.heading3}
  text-align: left;
`;

export const PriceText = styled.span`
  ${({ theme }) => theme.typography.subheading1}
  color: ${({ theme }) => theme.colors.secondary.kale};
  text-align: left;
`;

export const Text = styled.span`
  ${({ theme }) => theme.typography.bodyBold}
  color: ${({ theme }) => theme.colors.text.secondary};
  text-align: left;
`;

export const DeliveryFrequencyText = styled.span`
  ${({ theme }) => theme.typography.bodyBold}
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  text-align: left;
`;
export const DailyCaloriesText = styled.span`
  ${({ theme }) => theme.typography.subheading1}
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const TextHeader = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-top: ${({ theme }) => theme.spacing(6)};
  text-align: left;
`;

export const BoxStyledTitle = styled.span`
  ${({ theme }) => theme.typography.subheading2}
  color: ${props =>
    props.isCurrentPlan
      ? props.theme.colors.text.secondary
      : props.theme.colors.text.lightest};
  background-color: ${props =>
    props.isCurrentPlan
      ? props.theme.colors.neutral.bone
      : props.theme.colors.secondary.kale};
  padding: ${props => props.theme.spacing(2, 8)};
  opacity: ${props => props.isCurrentPlan && '0.5'};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  height: 28px;
`;
