import * as yup from 'yup';
import { requiredImageFromCMSSchema } from '../../cms';

export const schema = yup
  .object({
    image: requiredImageFromCMSSchema,
    title: yup.string().required(),
    subtitle: yup.string().required(),
    infoText: yup.string().required(),
    buttonLabel: yup.string().required(),
    buttonVariant: yup.string().required(),
    buttonUrl: yup.string().required(),
  })
  .transform(value => ({
    image: value.image,
    title: value.title,
    subtitle: value.subtitle,
    infoText: value.more_info_text,
    buttonLabel: value.button_label,
    buttonVariant: value.button_variant,
    buttonUrl: value.button_url,
  }));
